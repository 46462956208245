.progress {
  height: 30px;
}

.bg-light {
  color: black;
}

#incomplete {
  background-color: #AB7C94;
}

.followerChartContainer {
  margin-bottom: -20px;
}
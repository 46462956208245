/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

#minTweet {
  border: solid 1px #ced3da;
  border-bottom: none;
}

#inputContainer {
  flex-wrap: nowrap;
}

#formContainer {
  flex-grow: 1;
}

#followerGain {
  border-left: solid 1px #ced3da;
  border-right: solid 1px #ced3da;
}

#stakePerTweet {
  border-left: solid 1px #ced3da;
  border-right: solid 1px #ced3da;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  #minTweet {
    border-right: none;
  }

  #followerGain {
    border-top: solid 1px #ced4da;
    border-left: none;
  }

  #stakePerTweet {
    border-top: solid 1px #ced4da;
    border-left: none;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
  
}
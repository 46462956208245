
#logo {
  max-width: 40px;
}

#banner {
  transition-timing-function: linear;
  transition-duration: 50ms;
}

#navbar {
  background: rgba(255, 255, 255, 0);
  --breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");

}

.breadcrumbNav {
  --bs-breadcrumb-divider: var(--breadcrumb-divider);
}

#badge10, #badge50, #badge100, #creator, #tweetActivity, #followerGrowth, #noMissedDays, #challengeInviter, #tweepWarsInviter, #invitee {
  max-width: 50px;
}

/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #offcanvasScrolling {
    width: 400px;
  }
}

@media (min-width: 1280px) {
  #offcanvasScrolling {
    width: 420px;
  }
}

@media (min-width: 1400px) {
  #offcanvasScrolling {
    width: 500px;
  }
}

@media (min-width: 1680px) {
  #offcanvasScrolling {
    width: 620px;
  }
}
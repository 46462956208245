#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: -1;
}


.particle-speed-command {
  align-items: center;
  margin-top: 5rem;
  font-size: 0.8rem;
  text-align: right;
}

#decrease, #increase {
  font-size: 0.8rem;
}